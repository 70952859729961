// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
/* purgecss start ignore */
@import '../vendors/font-awesome/font-awesome';
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
// @import '../vendors/parallax-scroll/normalize';
// @import '../vendors/parallax-scroll/main';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

section.section-01-img-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 125px 0;

    div.container-fluid {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            padding: 15px;
            transition: .5s;
        }

        div.left-side-img,
        div.right-side-text {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
        }

        div.left-side-img {
            background: url(../images/section_01_bg_parallax.jpg) no-repeat center center;
            background-attachment: fixed;
            background-size: 40%;
            background-position-x: 7vw;
            justify-content: flex-start;
            align-items: center;
            display: flex;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                min-height: 550px;
                background-size: 80%;
                background-position: center;
                margin-bottom: 75px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    min-height: 475px;
                    margin-bottom: 0;
                    transition: .5s;
                }
            }

            .red-box {
                width: 82%;
                height: 172px;
                background: $color-2;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 5vw;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    height: 162px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        height: 145px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 100%;
                            padding: 15px;
                            justify-content: center;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                height: 95px;
                                transition: .5s;
                            }
                        }
                    }
                }

                .text-in-box {
                    color: $color-white;
                    font-size: 45px;
                    font-weight: 500;
                    line-height: 55px;
                    text-transform: none;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 40px;
                        line-height: 45px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 35px;
                            line-height: 40px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                text-align: center;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    font-size: 22px;
                                    line-height: 25px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }

                .text-in-box.left-text {
                    color: $color-white;
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 50px;
                    text-transform: none;
                    padding-left: 15px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-1) {
                        font-size: 38px;
                        line-height: 43px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 32px;
                            line-height: 44px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 28px;
                                line-height: 40px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 22px;
                                    line-height: 32px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        width: 100%;
                                        padding: 15px;
                                        font-size: 20px;
                                        text-align: center;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 18px;
                                            line-height: 22px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-4) {
                                                font-size: 16px;
                                                line-height: 20px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div.left-side-img.bg-empty {
            flex-direction: column;
            align-items: flex-start;
            transition: .5s;
            div.red-box {
                position: absolute;
                margin-top: 15vw;
                transition: .5s;
                width: 42%;
                @media screen and (max-width: $size-max-m-1) {
                    width: 98%;
                    padding: 0;
                    margin: 0;
                }
            }

            div.content-img {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }


        div.right-side-text {
            .content-paragraphs {
                width: 85%;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                }
                p {
                    color: $color-accent-1;
                    font-weight: 400;
                    font-size: 19px;
                    line-height: 30px;
                    @media screen and (max-width: $size-max-m-1) {
                        text-align: center;
                        transition: .5s;
                    }
                }

                p.bold-1-text-black {
                    font-weight: 600;
                    font-size: 32px;
                    margin-top: 20px;
                    line-height: 38px;
                    color: $color-accent-1;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 25px;
                        margin-top: 15px;
                        line-height: 32px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 22px;
                            margin-top: 20px;
                            line-height: 28px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 20px;
                                margin-top: 20px;
                                line-height: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 18px;
                                    margin-top: 20px;
                                    line-height: 28px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }

                p.big-letters {
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 18px;
                            line-height: 25px;
                            transition: .5s;
                        }
                    }
                }

                ul.list-specialites {
                    margin-top: 95px;
                    margin-bottom: 45px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        margin-top: 85px;
                        margin-bottom: 35px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            margin-top: 65px;
                            margin-bottom: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                               display: flex;
                               flex-direction: column;
                               align-items: center;
                               justify-content: center;
                                transition: .5s;
                            }
                        }
                    }
                    li {
                        font-size: 32px;
                        font-weight: 600;
                        color: $color-accent-1;
                        line-height: 55px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 25px;
                            line-height: 45px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 22px;
                                line-height: 35px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    font-size: 20px;
                                    line-height: 30px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 18px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div.content-logos-horizontals {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 35px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    justify-content: center;
                    transition: .5s;
                }
            }

            .last-prt {
                margin-top: 85px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding-left: 7vw;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    padding-left: 0vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        align-items: center;
                        transition: .5s;
                    }
                }

                h3.text-top-section {
                    text-transform: none;
                    font-weight: 600;
                    font-size: 20px;
                    color: $color-black;
                    width: 100%;
                    @media screen and (max-width: $size-max-m-1) {
                        text-align: center;
                        transition: .5s;
                    }
                }

                a.btn-border-red {
                    width: 300px;
                    height: 75px;
                    background: $color-2;
                    color: $color-white;
                    text-align: center;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 25px 0;
                    transition: .5s;
                    &:hover {
                        background: $color_main-hover;
                        transition: .5s;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        border: 2px solid $color-white;
                        font-size: 22px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    div.container-fluid.inner-pages {
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            padding: 0;
            transition: .5s;
        }

        div.left-side-img.inner-page.bg-empty  {
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                align-items: center;
                margin-bottom: 0;
                min-height: 200px;
                transition: 0s;
            }
        }

        div.right-side-text {
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                padding-top: 125px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    padding-top: 95px;
                    transition: .5s;
                }
            }
            @media screen and (max-width: $size-max-m-1) {
                width: 95%;
                padding-top: 0;
                transition: .5s;
            }
        }
    }

    div.container-fluid.inner-pages-remorque {
        @media screen and (max-width: $size-max-m-1) {
            padding: 0;
            transition: .5s;
        }
        div.left-side-img {
            @media screen and (max-width: $size-max-m-1) {
                align-items: center;
                padding-top: 45px;
                min-height: 250px;
                transition: .5s;
            }
            div.red-box {
                margin-top: 25vw;
                @media screen and (max-width: $size-max-m-1) {
                    width: 98%;
                    margin-top: 50vw;
                    transition: .5s;
                }
            }
        }
        div.right-side-text {
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 95%;
                transition: .5s;
            }
        }
    }

    div.container-fluid.inner-pages-industriel {
        align-items: flex-start;
        padding-top: 125px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            padding: 0;
            padding-top: 75px;
            justify-content: center;
            transition: .5s;
        }
        div.left-side-img {
            @media screen and (max-width: $size-max-m-1) {
                align-items: center;
                transition: .5s;
            }
            div.red-box {
                margin-top: 0;
            }
            div.box-outside {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 9vw;
                margin-top: 185px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    padding-right: 8vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding: 0;
                        justify-content: center;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            margin-top: 125px;
                            transition: .5s;
                        }
                    }
                }
                p {
                    width: 62%;
                    line-height: 30px;
                    font-weight: 500;
                    font-size: 24px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-0) {
                        width: 67%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            width: 60%;
                            font-size: 22px;
                            line-height: 28px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                //width: 55%;
                                font-size: 22px;
                                line-height: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-4) {
                                    //width: 50%;
                                    font-size: 22px;
                                    line-height: 22px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        width: 80%;
                                        font-size: 20px;
                                        line-height: 22px;
                                        text-align: center;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 18px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div.content-img {
                width: 55%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                }
            }
        }
        div.right-side-text {
            div.content-paragraphs {
                p {
                    width: 100%;
                    font-size: 20px;
                    font-weight: 400;
                }

                ul.list-accessoires {
                    list-style-type: none;
                    list-style: none;
                    margin-top: 25px;
                    padding: 0;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        transition: .5s;
                    }
                    li {
                        font-size: 22px;
                        text-transform: none;
                        color: $color-accent-1;
                        margin-bottom: 15px;
                        line-height: 25px;
                        font-weight: 500;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            text-align: center;
                            font-size: 20px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 18px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }

            div.last-prt {
                padding-left: .5vw;
                transition: .5s;
            }
        }
    }

    div.container-fluid.inner-pages.p-2 {
        div.left-side-img.inner-page {
            div.red-box {
                margin-top: 34vw;
            }
        }
    }

}

section.section-02-mix-burst {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0;
    padding-bottom: 0;

    div.container-fluid {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0;
        width: 100%;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            transition: .5s;
        }

        div.left-side-logos,
        div.content-burst-section {
            width: 50%;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
        }

        div.left-side-logos {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            div.top-content {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                ul.list-logos {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    list-style: none;
                    list-style-type: none;
                    padding: 0;
                    margin: 0 auto;
                    margin-bottom: 1.9vw;
                    width: 100%;

                    li.item-logo {
                        width: 30%;
                    }
                }
            }
        }
    }
}

section.section-header.index {
    div.module-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .caption {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            z-index: 99;
            text-align: center;
            transform: translateY(0%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h3 {
                min-width: 300px;
                font-weight: 700;
                position: absolute;
                color: $color-white;
                font-size: 3.2vw;
                text-align: center;
                line-height: 1;
                text-shadow: 2px 2px 5px $color-black,
                             -2px -2px 5px $color-black,
                             2px -2px 5px $color-black,
                             -2px 2px 5px $color-black;
                //// Modif - code
                width: 35vw;
                display: flex;
                min-height: 90px;
                padding: 1.7vw 15px;
                align-items: center;
                justify-content: center;
                border: 2px solid $color-white;
                margin-top: 2vw;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    width: 40vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        width: 50vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 60vw;
                            font-size: 28px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 28px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    font-size: 28px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-map-top-01 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 125px;
    transition: .5s;
    &:nth-child(2) {
        margin-top: 15px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
            margin-top: 45px;
            transition: .5s;
        }
    }
    div.container-fluid {
        display: flex;
        align-content: center;
        align-items: flex-start;
        transition: .5s;
        width: 100%;
        padding: 0;
        @media screen and (max-width: $size-max-m-2) {
            align-items: center;
            flex-direction: column;
            transition: .5s;
        }

        div.left-infos-contacts,
        div.right-map {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                width: 100%;
                transition: .5s;
            }
        }

        div.left-infos-contacts {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: column;
            padding-right: 5vw;
            padding-top: 155px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                padding: 0;
                align-items: center;
                transition: .5s;
            }

            div.top-infos-contacts {
                width: 65%;
                padding: 25px 0;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                flex-direction: column;
                border-top: 3px solid $color-2;
                border-bottom: 3px solid $color-2;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    width: 75%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 85%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 98%;
                            align-items: center;
                            transition: .5s;
                        }
                    }
                }

                h3 {
                    width: 100%;
                    color: $color-accent-1;
                    text-align: right;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        text-align: center;
                        transition: .5s;
                    }
                    a {
                        color: $color-accent-1;
                        transition: .5s;
                    }
                }

                h3.local-address {
                    font-size: 35px;
                    font-weight: 500;
                    line-height: 38px;
                    margin-bottom: 25px;
                    text-transform: none;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 30px;
                        line-height: 35px;
                        margin-bottom: 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 25px;
                            line-height: 30px;
                            margin-bottom: 20px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 22px;
                                line-height: 28px;
                                margin-bottom: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 100%;
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
                h3.phone {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 32px;
                    margin-bottom: 15px;
                    text-transform: none;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 100%;
                        margin-bottom: 0;
                        text-align: center;
                        transition: .5s;
                    }
                    &:hover {
                        transition: .5s;
                        a {
                            color: $color-2;
                            transition: .5s;
                        }
                    }
                    a {
                        font-size: 52px;
                        font-weight: 400;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 40px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 32px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 28px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }

            div.bottom-work-time {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    padding-bottom: 32px;
                    transition: .5s;
                }

                h3.title-work-time {
                    color: $color-2;
                    font-size: 42px;
                    font-weight: 600;
                    margin-top: 35px;
                    margin-bottom: 25px;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 35px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 100%;
                                    font-size: 22px;
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }

                h3.worh-time {
                    font-weight: 400;
                    color: $color-accent-1;
                    text-transform: none;
                    margin-bottom: 0px;
                    font-size: 28px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 22px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 100%;
                                    transition: .5s;
                                    font-size: 18px;
                                    text-align: center;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                    span {
                        font-weight: 600;
                        font-size: 45px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 35px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 30px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 25px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 22px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

div.section-form {
    //padding: 75px 0;
    margin: 95px 0;

    div.header-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        padding: 75px 0;
        transition: .5s;
        @media screen and (max-width: $size-max-2) {
            padding: 45px 0;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                padding: 25px 0;
                transition: .5s;
            }
        }
        h3 {
            width: 100%;
            font-size: 32px;
            text-align: center;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                font-size: 25px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 22px;
                    transition: .5s;
                }
            }
        }
    }

    section.input-section,
    div.input-section  {
        div.form-group {
            label {
                color: $color-accent-1;
                font-size: 18px;
                transition: .5s;
            }
            input.form-control {
                border-radius: 1px;
                padding: 10px 15px;
                transition: .5s;
                font-size: 18px;
                height: 45px;
            }
            select.type-de-demande {
                color: $color-accent-1;
                border-radius: 1px;
                padding: 10px 5px;
                transition: .5s;
                font-size: 18px;
                height: 45px;
                width: 100%;
            }
            textarea {
                resize: vertical;
                border-radius: 1px;
                padding: 10px 15px;
                font-size: 18px;
                transition: .5s;
            }
        }

        div.help-block.with-errors {
            ul.list-unstyled {
                li {
                    position: absolute;
                    margin-top: -13px;
                    font-size: 12px;
                }
            }
        }
    }

    div.content-btn-recpatcha {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: .5s;
        div.form-group {
            display: flex;
            align-items: center;
            justify-content: center;

            div.btn-submit {
                width: 300px;
                button {
                    width: 100%;
                    min-height: 45px;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color-2;
                    color: $color-white;
                    transition: .5s;
                    &:hover {
                        //border: 0 solid $color-black;
                        //transition: .5s;
                    }
                    span {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid $color-white;
                        min-height: 45px;
                    }
                }
            }
        }
    }
}

div#bodyContent {
    p {
        color: $color-accent-1;
        transition: .5s;
        &:hover {
            transition: .5s;
            a {
                color: $color-2;
                transition: .5s
            }
        }
        a {
            color: $color-accent-1;
        }
    }
}

//////// Shorcuts
.bg-empty {
    background-size: 0 0!important;
}
.pb-0 {
    padding-bottom: 0!important;
}
.pt-0 {
    padding-top: 0!important;
}
.bold-none {
    font-weight: 400!important;
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 1;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


// .shadow {
//     position: relative;

//     &::after {
//         content: '';
//         z-index: 0;
//         width: 100%;
//         top: 100%;
//         height: 40px;
//         position: absolute;
//         background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
//     }
// }

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';


@import 'sections/section-bursts-icons';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-soumission';
//@import 'modules/module-header';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
