// CSS Variables for the project.

// Mettre les différentes variables tirées du fichier psd du designer

//********************/
//* FONTS
//* Allez configurer les fonts dans _fonts.scss

// font size de base:
$font-size-base-lg: 20px;
$font-size-base-md: 18px;
$font-size-base-sm: 15px;
$font-size-base-xs: 12px;
@import 'utilities/font-sizes'; // variables for font-sizes using rem units

//********************/
// COLORS
//COULEURS PRINCIPALES
$color_main: rgb(196,20,20) ;  // ou rgb(196/20/20)
$color_main-hover: rgb(124, 0, 0) ;  // ou rgb(196/20/20)
$color-accent-1: rgb(125,125,125)  ;  // ou rgb(125/125/125)
//$color-accent-2: #  ;  // ou rgb() ou hsl()
$color-black: #000000 ;
$color-white: #FFFFFF ;

//COULEURS BACKGROUND
$color-bg-body: #FFFFFF ;
$color-bg-overlay-1: rgba(0,0,0, 0.5) ; // darker ou rgba(#000000, 0.5)
$color-bg-overlay-2: rgba(0,0,0, 0.3) ; // clearer ou rgba(#000000, 0.3)

$color-1: $color-white; // needed
$color-2: $color_main; // needed
$color-3: #003F5E;

$color-primary: $color-1; // needed
$color-secondary: $color-2; // needed
$color-tertiary: $color-1; // needed 

$color-black: #000; // needed
$color-white: #fff; // needed
$color-grey: #58585a; // needed
$color-grey-dark: #353535; // needed body
$color-grey-darker: #262523; // footer
$color-grey-light: #b5b5b5;

$color-error: red; // needed error messages
$color-success: green; // needed success messages

// TEXT COLORS
$color-text-base: $color-grey-darker; // Mettre la couleur
$color-text-punch: $color-secondary; // Mettre la couleur
$color-text-contrast: $color-white; // Mettre la couleur
$color-text-gris: $color-grey;

$color-text-base-light: lighten($color-text-base, 20%);
$color-text-punch-light: lighten($color-text-punch, 20%);
$color-text-contrast-light: lighten($color-text-contrast, 20%);
$color-text-base-dark: darken($color-text-base, 20%);
$color-text-punch-dark: darken($color-text-punch, 20%);
$color-text-contrast-dark: darken($color-text-contrast, 20%);

// BACKGROUNDS COLORS
$bg-color-body: $color-white; // needed
$bg-color-nav: rgba(255, 255, 255, 0.35); // needed
$bg-color-nav-filled: rgba(253, 253, 253, 0.95); // needed
$bg-color-nav-logo: $bg-color-nav; // needed
$bg-color-nav-highlight: $color-secondary; // needed

$bg-color-black: $color-black; // needed
$bg-color-transparent: rgba(0, 0, 0, 0.4);
$bg-color-footer: $color-grey-darker; // needed
$bg-color-primary: darken($color-1, 0.1); // needed
$bg-color-secondary: darken($color-2, 0.1); // needed

// LINE COLORS
$line-color-nav-mobile: $color-secondary;

// ********************
// RESPONSIVE
//

$size-xs-max: 767px;
$size-sm-min: 768px;
$size-sm-max: 990px;
$size-md-min: 991px;
$size-md-max: 1299px;
$size-lg-min: 1300px;

$size-max-0: 1785px;
$size-max-1: 1755px;
$size-max-2: 1575px;
$size-max-3: 1355px;
$size-max-4: 1199px;
$size-max-5: 1099px;

$size-max-m-1-91 : 901px;
$size-max-m-1: 991px;
$size-max-m-2: 767px;
$size-max-m-3: 550px;
$size-max-m-4: 480px;

$size-nav-shift-breakpoint: $size-md-max; // change height
$size-nav-toggle-breakpoint: $size-sm-max; // show mobile menu

$size-slider-breakpoint: 480px;

// ********************
// SIZES
//

$size-footer-height: 100px; // needed
$size-footer-width-desktop: 50%; // needed
$size-footer-width-mobile: 100%; // needed
$size-nav-height: 100px; // needed
$size-nav-height-mobile: 75px; // needed
$size-nav-subitem: 25px; // needed
$size-nav-toggle-bar-height: 1px; // needed

// ******************
// Z-INDEXES
//
$z-index-0: 0;
$z-index-animation-0: 10;
$z-index-animation-1: 15;
$z-index-animation-2: 20;
$z-index-menu: 500;
$z-index-submenu: 505;
$z-index-menu-mobile: 20000;

// ******************
// TRANSITION SPEED
//
$transition-speed-very-slow: 1s;
$transition-speed-slow: 0.7s;
$transition-speed-normal: 0.5s;
$transition-speed-fast: 0.3s;
$transition-speed-very-fast: 0.1s;
