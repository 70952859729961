.slideshow-wrap {
    position: relative;
    //@extend .bg-color-black;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;

        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            .col-xs-12 {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @media screen and (max-width: $size-max-m-2) {
                    margin-top: 40px;
                    transition: .5s;
                }

                img {
                    @media screen and (max-width: $size-max-3) {
                        width: 80%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            width: 72%;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                width: 62%;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 40%;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        width: 60%;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }

                h3 {
                    text-align: center;
                    color: $color-white;
                    font-size: 48px;
                    font-weight: 600;
                    text-shadow: 0 0 10px #000;
                    margin-top: 25px;
                    transition: .5s;

                    @media screen and (max-width: $size-max-3) {
                        font-size: 40px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 35px;
                            margin-top: 15px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 22px;
                                margin-top: 15px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 30px;
                                    margin-top: 0;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        font-size: 24px;
                                        margin-top: 20px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }

                h4 {
                    text-align: center;
                    color: $color-white;
                    font-size: 42px;
                    font-weight: 600;
                    text-shadow: 0 0 10px #000;
                    margin-top: 5px;
                    transition: .5s;

                    @media screen and (max-width: $size-max-3) {
                        font-size: 35px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 28px;
                            margin-top: 15px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 22px;
                                margin-top: 15px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 25px;
                                    margin-top: 0;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        font-size: 24px;
                                        margin-top: 5px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}

    }
}

